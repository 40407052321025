$darkYellow: #FFC72C;
$lightYellow: #FFEEBF;
$white: #FFFFFF;
@mixin boxWhiteWithShadow{
  border: 0.6px solid #ECE2E2;
  box-shadow: 1px 1px 6px rgba(122,105,105,0.25);
  border-radius: 8px;
}

@mixin textUsual{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.05em;
  color: #484343;
}

