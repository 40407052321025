.user-data-inputs {
  // overflow-y: scroll;
}

.action-cell {
  display: flex;

}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.row-content {
  overflow: scroll;
  height: calc(100vh - 150px);
}

.chevron-container {
  position: relative;

  .chevron-img {
    position: absolute;
    width: 10px;
    height: auto;
    right: 5px;
    top: 43px;
    z-index: 9999;
  }
}

.create-pass-dyn-valid-para {
  display: flex;
  flex-direction: row;
  color: #222434;
  margin-bottom: 8px;

  div {
    margin-left: 8px;
  }
}

.invalid {
  color: #5E6291
}

.pass-input-wrapper {
  position: relative;

  .edit-user-pass-icon {
    position: absolute;
    right: 25px;
    top: -3px;
  }

  .create-user-pass-icon {
    position: absolute;
    right: 25px;
    top: -1px;
  }

  .form-group {

    input:disabled {
      background-color: #ded9d9;
    }
  }
}

.error-custom-user-edit {
  bottom: 48vh;
}

.switch-message-wrapper {
  display: flex;
  width: max-content;
  flex-direction: row;
  align-items: center;
  //margin: auto;
  .enabled-pass-message {
    color: #626ed4;
  }
}


.styles-inner-pages-wrapper {
  position: relative;
  margin: 0;
  background: rgb(246, 246, 245);
  padding-top: 17px !important;
  padding-right: 44px !important;
  padding-left: 25px !important;

  .edit-user-wrapper {
    background-color: #fff !important;
    margin: 10px 10px 10px 10px;
    border-radius: 8px;
    height: fit-content;
    max-height: 86vh;
    overflow-y: auto;
    .close{
      display: none;
    }

    .organizations-tab {
      height: 100vh;
    }
  }
}
