@import 'const.scss';
@import './node_modules/bootstrap/scss/mixins/_utilities.scss';

.bg-warning {
  background-color: $darkYellow !important;

  .stats-card-title {
    font-style: normal;
    font-weight: 500;
    color: rgb(52, 52, 52);
    white-space: nowrap;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0.05em;

  }

  .stats-card-data {
    color: rgb(88, 88, 88);
  }
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Roboto, 'Plus Jakarta Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

}

html, body {
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
  background: rgb(246, 246, 245);
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.quidzi-btn-light {
  width: max-content;
  min-width: 90px;
  display: block;
  height: 30px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.05em;
  cursor: pointer;
  box-shadow: rgba(122, 105, 105, 0.1) 0px 1px 4px 0px;
  border-radius: 6px;
  text-transform: uppercase;
  color: rgb(83, 75, 79);
  background-color: rgb(255, 255, 255);
  border: 0.6px solid rgb(236, 226, 226);

  &:hover {
    color: rgb(72, 67, 67);
    border: 0.6px solid rgb(255, 199, 44);
    background-color: rgb(255, 238, 191);
  }
}

.quidzi-btn-dark {
  width: max-content;
  min-width: 90px;
  display: block;
  height: 30px;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.05em;
  cursor: pointer;
  box-shadow: rgba(122, 105, 105, 0.1) 0px 1px 4px 0px;
  border-radius: 6px;
  text-transform: uppercase;
  color: #FFFFFF;
  background-color: #534B4F;
  border: 0.6px solid #484343;

  &:hover {
    color: #FFC72C;
    border: 0.6px solid #FFC72C;
  }

}

.quidzi-input-borderered {
  font-size: 14px;
  line-height: 21px;
  color: #242633;
  width: 100%;
  height: 30px;
  background: transparent;
  padding-left: 8px;
  border-radius: 6px;
  border: 0.6px solid #ECE2E2;

  &:focus {
    border: 1px solid #ffc72c;
  }

  &:-webkit-autofill {
    background: $darkYellow;
  }


}

.select-quidzi {
  padding: 0 0 0 8px;
}


.quidzi-pagination {

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    color: #585858;
  }
}

#page-header-user-dropdown, #page-header-expand-btn {
  background: rgb(246, 246, 245);
  border-bottom: 1px solid rgba(29, 29, 29, 0.08);
}

.i-can-see-block {
  display: block !important;
}

.i-see-grey-color {
  color: grey;
}
.mh-90{
  max-height: 90px;
}
.dropdown-toggle, .dropdown-item {
  &:hover {
    background: $lightYellow;
  }
}
