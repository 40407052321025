@import "const.scss";
.login {
  width: 100vw;
  display: flex;
  height: 113vh;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f1fb;
  position: fixed;
  top: -5em;
}

.login_bg {

  background-size: cover;
  width: 100%;
  height: 100%;
}

.login-card{
  @include boxWhiteWithShadow;
  box-shadow: 0 1px 4px rgba(0,0,0,.25), 0 4px 16px rgba(0,0,0,.15);
  img{
    width: 133px;
    height: 34px;
  }
}
