.user-main-wrapper {
  margin-top: 7vh;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  background-color: #F3F4FC !important;

  .styles-wrapper {
    position: relative;
    margin: 0;
    background-color: #F3F4FC;

    .row-all-content {
      background-color: white;
      border-radius: 8px 8px 0px 0px;

      .row-cards-block {
        margin-left: 1px;
      }

      .col-table-actions.col-table-actions {
        margin-bottom: 10px;
      }
    }

  }
}

.row-table-content {
  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;
  background-color: white;
  border-radius: 0px 0px 8px 8px;
  height: calc(83vh - 150px);
  overflow-y: auto;
  scrollbar-width: 1px;
}

.action-in-table {
  display: flex;
  justify-content: space-around;
  i{
    cursor: pointer;
    font-size: 16px;
  }
}

.user-table-filter-block {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    margin-right: 1vw;
  }

  .clear-button,
  .switch-component {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

  }

  .active-add-block {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    bottom: 0;

    .add-icon-block {
      position: relative;

      .add-icon {
        position: absolute;
        left: 0;
        top: -6px;
        cursor: pointer;
      }
    }
  }
}

.user-action {
  cursor: pointer;
}

.inactive {
  color: rgb(201, 198, 198);
}

.role {
  cursor: pointer;
}

.popover-header {
  background-color: grey !important;
  color: wheat;
}

.drop-option {
  font-size: larger;
  cursor: pointer;
}

.drop-option:hover {
  background-color: grey;
  border-radius: 5px;
  color: wheat;
}

.success-custom {
  width: max-content;
  margin: auto;
  position: absolute;
  bottom: 10vh;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.users-content {
  overflow: scroll;
  height: calc(100vh - 100px);
}

.dropdown {
  button {
    width: 100%;
    background-color: white;
    border: none;
  }
}

.dropdown-menu {
  -webkit-box-shadow: 4px 4px 15px 6px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 4px 15px 6px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 4px 15px 6px rgba(34, 60, 80, 0.2);
}

.chevron-container {
  position: relative;

  .chevron-img-user-page {
    position: absolute;
    width: 10px;
    height: auto;
    right: 5px;
    top: 13px;
    z-index: 1049;

    &.in-modal {
      top: 43px;
    }
  }
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.alert-wrapper {
  z-index: 9999;
  position: absolute;
  width: 100%;
  bottom: -10vh;
  left: 0;

  .alert-card {
    width: max-content;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
}

.stats-card-title {
  //styleName: 14px Medium;
  //font-family: Sora;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #5E6291;
  text-overflow: ellipsis;
  overflow: hidden;
}

.stats-card-data {
  //styleName: 16px Medium;
  //font-family: Sora;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #222434;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-control {
  &.is-invalid {
    &.warning {
      background-position: right calc(1.375em + 0.1875rem) center;
    }
  }
}
