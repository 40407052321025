.fabrics-main-wrapper{
  padding-top:10.2vh ;
  height:96.6vh;
  padding-left: 36px !important;
  padding-right:36px !important;
  background-color:#F3F4FC;
  .fabrics-white-card{
    border-radius: 8px;
    background-color:white;
    //border:1px solid black;
    input:disabled{
      background-color: #ded9d9;
    }
  }
}
.doc-delete-alert{
  position: absolute;
  bottom:80px;


  width:100%;
  .alert{
    width:max-content;

  }
}
input:disabled{
  background-color: #ded9d9 !important;
}
.async-select-error{
  div{
    border-color:red;
  }
}
.chevron-img-event-modal{
  width: auto;
  height: 12px;
  position: absolute;

  bottom: 10px;
  right: 10px;
}
.th{
  display: flex;
  align-items: center;
  vertical-align:center;
}
.footer-text {
  span {
    color: #626ed4;
    cursor: pointer
  }

}
.providers-main-wrapper{
  position: relative;
  padding-top: 8.2vh;
  height:96.6vh;
  padding-left: 36px !important;
  padding-right:36px !important;
  background-color:rgb(246, 246, 245);;
  .provider-white-card{
    position: relative;
    border-radius: 8px;
    background-color:white;
    //border:1px solid black;
  }

}


